.modal {
    position: fixed;
    top: 5%;
    left: 50%;
    width: 50%;
    height: auto;
    z-index: 2000;
    visibility: hidden;
    transform: translateX(-50%) translateY(-5%);
    margin: 0;
    margin-top: 15px;
}

.modal.show {
    visibility: visible;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: #2226;
    transition: all 0.3s;
}

.modal.show~.overlay {
    opacity: 1;
    visibility: visible;
}

.modal-content {
    color: #fff;
    background: #e1075e;
    position: relative;
    border-top-right-radius: 25px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 3px;
    margin: 0 auto;
    padding: 15px 50px 40px;
    max-height: calc(100vh - 100px);
    overflow-y: overlay;
    overflow-x: hidden;
}

.modal-content ul {
    list-style: none;
    margin: 10px;
}

.zoomInEffect .modal-content {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.4s;
}

.modal.show.zoomInEffect .modal-content {
    transform: scale(1);
    opacity: 1;
}

.showPageTitle {
    margin: 0 auto;
    text-align: center;
}

.showPageHosts {
    text-align: center;
    display: block; 
    color: #ccc;
}

.showPageContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 25px;
    margin: 50px 0;
}

.showPageImage {
    width: 250px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center; 
}

.showPageDetails {
    overflow-wrap: anywhere;
    flex-grow: 1;
}

.showEpisode {
    display: flex;
    flex-direction: row;
    background-color: #f6d7b0;
    padding: 10px;
    border-radius: 6px;
    margin: 6px 0;
    align-items: center;
    opacity: 0.8;
    transition: all 0.2s;
    color: #444;
    gap: 10px
}

.showEpisode:hover {
    opacity: 1;
}

.showEpisode.active {
    opacity: 1;
}

.showEpisode > img {
    cursor: pointer;
    transition: 0.1s;
    width: 25px;
    filter: grayscale(1) contrast(0) brightness(0.6);
}

.showEpisode > img:hover {
    transform: scale(1.1);
}

.showEpisode > b {
    white-space: nowrap;
}

@media only screen and (max-width: 1024px) {
    .modal {
        width: 90%;
        margin: 0;
        margin-top: 15px;
    }

    .modal-content {
        padding: 0px 25px;
        max-height: calc(100vh - 100px);
        border-top-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .showPageContent {
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
    }

    .modal .spacer {
        height: 50px;
    }

    .showEpisode {
        flex-wrap: wrap;
    }

    .showEpisode > b {
        order: 1;
    }

    .showEpisode > img {
        order: 2;
        margin: auto;
    }

    .showEpisode > span {
        order: 3;
    }

    .showEpisode > input {
        order: 4;
    }


}

.player-seeker {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    background: #777;
    overflow: hidden;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    background-color: #9a905d;
    border-radius: 15px;
}

.player-seeker::-webkit-slider-thumb {
    width: 0px;
    -webkit-appearance: none;
    height: 0px;
    background: #434343;
    box-shadow: -100vw 0 0 100vw #292929;
    border-radius: 15px;
}

.player-seeker::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-right: 0px;
}

/** Custom Scrollbar */
.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    margin: 20px 0 0 0;
    border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: #444;
}

.modal-content::-webkit-scrollbar-thumb:active {
    background: #323232;
}