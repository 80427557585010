table#schedule td {
    cursor: pointer;
    transition: 0.1s ease-in;
}

table#schedule td:hover {
    background-color: #bf0a54;
}

table#schedule tbody tr th {
    border-bottom: 1px solid;
}

table#schedule tbody tr:last-child th {
    border-bottom: 0;
}