@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300&display=swap');

.mainPageContent {
    margin: auto;
    width: 100%;
}

.currentShows-card {
    background-image: url('../public/assets/show-img-placeholder1.png');
    background-size: cover;
    height: 225px;
    position: relative;
}

.currentShows-card>.currentShows-card-shadow {
    background-image: url('../public/assets/shadow.png');
    height: 112px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.currentShows-card>.currentShows-card-onairtime,
.currentShows-card>.currentShows-card-onairshow,
.currentShows-card>.currentShows-card-socialstitle,
.currentShows-card>.currentShows-card-socialsbuttons {
    position: absolute;
    bottom: 0;
    display: inline;
    padding: 5px 10px;
    color: white;
    font-family: "Barlow Semi Condensed", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22px;
}

.currentShows-card>.currentShows-card-onairtime {
    margin-bottom: 30px;
    background-color: #e1075e;
}

.currentShows-card>.currentShows-card-onairshow {
    margin-bottom: -15px;
    background-color: #e1075e;
}

.currentShows-card>.currentShows-card-socialstitle {
    background-color: #fff;
    color: #2c2c2c;
    right: 0;
    margin-bottom: 80px;
}

.currentShows-card>.currentShows-card-socialsbuttons {
    right: 0;
    margin-bottom: 35px;
}
.currentShows-card>.currentShows-card-socialsbuttons > span > a {
    color: white
}

.currentShows-card>.currentShows-card-socialsbuttons > span > a > i {
    background-color: #fff;
    padding: 3px 2px 7px;
    border-radius: 20px;
    margin-left: 5px;
    color: #2c2c2c;
    
}

.InstagramInfo {
    color: #e1075e;
    font-family: "Barlow Semi Condensed", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}

.InstagramInfo a {
    color: #ac0a4b;
    margin-left: 3px;
}

.InstagramInfo a:hover {
    color: #e1075e;
}