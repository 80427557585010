body {
    margin: 0;
    background-color: #2c2c2c;
    color: #fff;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

::selection {
    background: transparent;
}

header {
    background-color: #2c2c2c;
    width: 100%;
    display: block;
    height: 65px;
    position: fixed;
    top: 0;
    z-index: 1;
    opacity: 1;
    transition: 2s;
}

header.hide {
    opacity: 0;
}

header>img {
    opacity: 1;
    transition: 1s;
}

header.fade>img {
    opacity: 0;
}

#mainPage {
    margin-top: 65px;
    flex-direction: column;
    transition: 1s;
    position: absolute;
    min-height: calc(100% - 65px);
    width: 100%;
}

#mainPage.hide {
    background-color: #2c2c2c;
}

#mainPage.hide>* {
    opacity: 0;
    transition: 1s;
}

header>img {
    margin: auto;
    max-height: 50px;
    display: block;
    padding: 10px;
}

#loader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.5s;
    bottom: 0;
}

#loader.hide {
    opacity: 0;
    height: 0%;
}

.player {
    position: fixed;
    bottom: 0;
    z-index: 3;
    transition: 0.8s;
}

.player.modal-open {
    transform: scale(0.5) skew(-15deg);
    filter: blur(5px);
}

.player.expand {
    position: fixed;
    bottom: 0px;
}

footer {
    bottom: 100vh;
    position: absolute;
    z-index: 20;
    height: 65px;
    width: 100%;
    transition: 2s;
}

.player.hide>footer {
    bottom: 0vh;
    top: -115vh;
}

.player.show>footer {
    bottom: 0vh;
    top: -65px;
}

footer>.playerData {
    display: flex;
}

.player.expand>footer {
    top: -100vh;
    margin-top: 10px;
}

.player.expand.mobile>footer {
    top: -90vh;
    margin-top: 10px;
}

#wave {
    bottom: 0;
    width: 100%;
    position: fixed;
    background: rgb(224 16 100);
    height: 100px;
    padding-bottom: 100vh;
    z-index: -1;
    transition: 2s;
}

.player.show>#wave {
    padding-bottom: 0vh;
}

.player.expand>#wave {
    padding-bottom: 100vh;
}

#wave>div>svg {
    background-color: #2c2c2c;
    margin-bottom: 0px;
    transition: 1s;
}

.player.expand #wave>div>svg {
    background-color: #2c2c2c;
}

.playerData>div {
    flex-grow: 1;
    align-items: center;
    color: white;
}

.playerData>.streamActionBtn {
    flex-grow: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.playerData>.sectionExpansionBtn {
    flex-grow: 0;
    width: 20px;
    height: 20px;
    align-self: center;
    margin-bottom: 10px;
    transition: 1s;
    padding: 10px;
    cursor: pointer;
}

.playerData>.sectionExpansionBtn.expand {
    transform: rotateZ(-180deg);
}

.showTitle {
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.showDesc {
    font-size: 16px;
    color: lightgray;
}

#error {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: crimson;
    color: white;
    font-size: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
}

#error.show {
    display: flex;
}

#error>span {
    width: 80%;
    text-align: center;
}

select, select option {
    text-align: center;
}

#error>a {
    color: black;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
}

.currentSongBarContainer {
    margin-top: 11px;
    border: 1px #2c2c2c;
    border-style: solid;
}

.currentSongBar {
    background-color: white;
    height: 4px;
    width: 0;
}

#footerSections {
    height: 85vh;
    overflow: overlay;
    padding-bottom: 20px;
    margin: 0 10px;
    transition: 6s;
    color: white;
    text-align: center;
}

#footerSections.hide {
    opacity: 0;
}